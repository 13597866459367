import React from "react";
import CustomBlock from "../../../customComponents/CustomBlock";
import { useTranslation } from 'react-i18next';
import LineChart from "../../../charts/LineChart";
import CustomButton from "../../../customComponents/CustomButton";
import { useDispatch } from "react-redux";
import { updateArianneThread } from "../../../arianneThread/ArianneThread";
import { setShowedAnalysis } from "../../../../actions_reducers/actions/dashboard.action";
const Moment = require("moment");
const MomentRange = require("moment-range");

const moment = MomentRange.extendMoment(Moment);
const Graph = (props) => {
    const dispatch = useDispatch()
    const { t } = useTranslation();
    const getColor = () => {
        switch (props.state.selectedAnalysisType) {
            case 'massByConv':
                return '#5BB3E4';
            case 'mass':
                return '#7DCBB9';
            case 'cost':
                return '#89CD85';
            case 'co2':
                return '#90c261';
            default:
                return '#5BB3E4';
        }
    }
    const convertMass = (mass) => {
        if (props.userState.massUnit === "lbs") {
            return parseFloat(mass / 453.6);
        }
        else return mass / 1000;
    }
    const getData = () => {
        let result = [
            {
                id: "analyzes_values",
                color: getColor(),
                data: [

                ]
            }
        ];
        let analyzes = props.analyzesDatas;
        let kitchenAnalyzes = props.kitchenDatas;
        if (props.state.selectedAnalysisType === 'massByConv') {
            analyzes = props.baseAnalysis;
            kitchenAnalyzes = props.baseAnalysisKitch
        }
        for (const analysis of analyzes) {
            let newDate = moment(analysis.dateEnd).add(-7, "days")
            let singleData = {
                name: moment(newDate).format("MM/YY"),
                x: analysis.analysisNumber,
                y: '',
                color: 'transparent'
            };
            switch (props.state.selectedAnalysisType) {
                case 'massByConv':
                    let massByConv = (parseFloat(analysis.mass) - parseFloat(analysis.inevitableMass)) / (parseFloat(analysis.trays) ? parseFloat(analysis.trays) : 1);
                    singleData.y = parseFloat(t('mass', { "value": { "mass": massByConv, "unit": "g" } }));
                    break;
                case 'mass':
                    let mass = (parseFloat(analysis.mass) - parseFloat(analysis.inevitableMass))
                    singleData.y = convertMass(mass);
                    break;
                case 'cost':
                    let cost = parseFloat(analysis.cost) - parseFloat(analysis.inevitableCost)
                    singleData.y = cost;
                    break;
                case 'co2':
                    let co2 = (parseFloat(analysis.co2) - parseFloat(analysis.inevitableCo2))
                    singleData.y = convertMass(co2);
                    break
                default:
                    let defaultValue = (parseFloat(analysis.mass) - parseFloat(analysis.inevitableMass)) / parseFloat(analysis.trays);
                    singleData.y = parseFloat(t('mass', { "value": { "mass": defaultValue, "unit": "g" } }));
                    break;
            }
            result[0].data.push(singleData);
        }
        for (const analysis of kitchenAnalyzes) {
            let newDate = moment(analysis.dateEnd).add(-7, "days")
            let index = result[0].data.findIndex(a => a.x === analysis.analysisNumber)
            let singleData = {
                name: moment(newDate).format("MM/YY"),
                x: analysis.analysisNumber,
                y: '',
                color: 'transparent'
            };
            switch (props.state.selectedAnalysisType) {
                case 'massByConv':
                    let massByConv = (parseFloat(analysis.mass) - parseFloat(analysis.inevitableMass)) / (parseFloat(analysis.trays) ? parseFloat(analysis.trays) : 1);
                    singleData.y = parseFloat(t('mass', { "value": { "mass": massByConv, "unit": "g" } }));
                    break;
                case 'mass':
                    let mass = (parseFloat(analysis.mass) - parseFloat(analysis.inevitableMass))
                    singleData.y = convertMass(mass);
                    break;
                case 'cost':
                    let cost = parseFloat(analysis.cost) - parseFloat(analysis.inevitableCost)
                    singleData.y = cost;
                    break;
                case 'co2':
                    let co2 = (parseFloat(analysis.co2) - parseFloat(analysis.inevitableCo2))
                    singleData.y = convertMass(co2);
                    break
                default:
                    let defaultValue = (parseFloat(analysis.mass) - parseFloat(analysis.inevitableMass)) / parseFloat(analysis.trays);
                    singleData.y = parseFloat(t('mass', { "value": { "mass": defaultValue, "unit": "g" } }));
                    break;
            }
            if (index === -1)
                result[0].data.push(singleData);
            else {
                result[0].data[index].y = parseFloat(result[0].data[index].y) + parseFloat(singleData.y)
            }
        }
        if (result[0].data[0]) {
            result[0].data.sort((a, b) => a.x - b.x)
            if (result[0].data.length > 11) {
                let indexes = [];
                let max = result[0].data.length - 12;
                for (let i = 1; i <= max; i++) {
                    indexes.push(i + 1)
                }
                for (const i of indexes) {
                    let index = result[0].data.findIndex(a => a.x === i)
                    result[0].data.splice(index, 1)
                }
                result[0].data.push({
                    name: "......",
                    x: 2,
                    y: result[0].data[0].y,
                    color: 'transparent'

                })
                result[0].data.sort((a, b) => a.x - b.x)
            }

            let data1 = JSON.parse(JSON.stringify(result[0].data[0]));
            data1.name = "";
            data1.x = 0;
            data1.color = 'transparent';
            result[0].data.unshift(data1);
            let data2 = JSON.parse(JSON.stringify(result[0].data[result[0].data.length - 1]));
            data2.name = "";
            data2.x = result[0].data[result[0].data.length - 1].x + 1;
            data2.color = 'transparent';
            result[0].data.push(data2);
        }
        return result;
    }
    const getSelectedAnalysisName = () => {
        if (props.state.selectedAnalysisNumber) {
            let analysis = props.analyzesDatas.find(a => parseInt(a.analysisNumber) === parseInt(props.state.selectedAnalysisNumber))
            if (!analysis) analysis = props.kitchenDatas.find(a => parseInt(a.analysisNumber) === parseInt(props.state.selectedAnalysisNumber))
            return moment(analysis.dateEnd).add(-7, "days").format("MM/YY");
        }
        else return "";

    }

    const getMassSelectedAnalysis = () => {
        let mass = 0;
        let co2 = 0;
        if (props.state.selectedAnalysisNumber) {
            let analysis = props.analyzesDatas.find(a => parseInt(a.analysisNumber) === parseInt(props.state.selectedAnalysisNumber))
            let kitchenAnalysis = props.kitchenDatas.find(a => parseInt(a.analysisNumber) === parseInt(props.state.selectedAnalysisNumber))
            if (analysis) {
                mass += parseFloat(analysis.mass) - parseFloat(analysis.inevitableMass)
                co2 += parseFloat(analysis.co2) - parseFloat(analysis.inevitableCo2)
            }
            if (kitchenAnalysis) {
                mass += parseFloat(kitchenAnalysis.mass) - parseFloat(kitchenAnalysis.inevitableMass)
                co2 += parseFloat(kitchenAnalysis.co2) - parseFloat(kitchenAnalysis.inevitableCo2)

            }
        }
        return { mass: mass, co2: co2 };
    }
    const getLegend = () => {
        let mass = getMassSelectedAnalysis().mass;
        let co2 = getMassSelectedAnalysis().co2;
        if (props.state.selectedAnalysisType === "cost") return t('graph.cost', { unit: props.userState.costUnit });
        if (props.userState.massUnit === "lbs") {
            if (props.state.selectedAnalysisType === "massByConv") return t('graph.massByConv', { unit: "oz" });
            else if (props.state.selectedAnalysisType === "mass") return t('graph.mass', { unit: "lbs" });
            else if (props.state.selectedAnalysisType === "co2") return t('graph.co2', { unit: "lbs" });
        }
        else {
            if (props.state.selectedAnalysisType === "massByConv") return t('graph.massByConv', { unit: "g" });
            else if (props.state.selectedAnalysisType === "mass") return t('graph.mass', { unit: "kg" });
            else if (props.state.selectedAnalysisType === "co2") return t('graph.co2', { unit: "kg" });
        }
    }
    const getMonthAnalysis = () => {
        let analysis = props.analyzesDatas.find(a => parseInt(a.analysisNumber) === parseInt(props.state.selectedAnalysisNumber));
        if (!analysis)
            analysis = props.kitchenDatas.find(a => parseInt(a.analysisNumber) === parseInt(props.state.selectedAnalysisNumber));
        const date = t('date', { value: { date: moment(analysis.dateEnd).subtract(7, "days").format("YYYY-MM-DD"), format: "MMMM YYYY" } });
        return date.charAt(0).toUpperCase() + date.slice(1)
    }
    return (
        <CustomBlock width={'100%'} height={'45vh'}>
            <div style={{ width: '100%', height: '100%', padding: '2%' }}>
                <div className="dashboard-block-title" style={{ height: "6%" }}>
                    {t(`components.graph${props.state.selectedAnalysisType}`)}
                    {(props.analyzesDatas.length > 0 || props.kitchenDatas.length > 0) && <CustomButton
                        onClick={() => {
                            updateArianneThread('detailedAnalysis', props.arianne, dispatch, getMonthAnalysis());
                            dispatch(setShowedAnalysis(props.state.selectedAnalysisNumber))
                        }}
                        width={"11%"} height={"180%"} position={"absolute"} backgroundColor={"#90c261"} color={"#fff"} top={0} right={0} value={getSelectedAnalysisName()} />}
                </div>
                <div style={{ fontSize: ".8vw", color: "#666666" }}>
                    {getLegend()}
                </div>
                <div className="dashboard-graph-body">
                    <LineChart
                        id={"analysis"}
                        data={getData()} color={getColor()} selectedAnalysisNumber={props.state.selectedAnalysisNumber} />
                </div>
            </div>
        </CustomBlock>
    )
}
export default Graph;