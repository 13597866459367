import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { deleteFileForRestaurant, downloadExcel, downloadFileRestaurant, getFilesOfRestaurantAndAnalysis, uploadFilesForRestaurant } from "../../../apiRoutes/files.routes";
import { useEffect } from "react";
import CustomBlock from "../../customComponents/CustomBlock";
import { useDispatch } from "react-redux";
import { setAnalyzesSynthesis, setFilesList, setLoadingFiles, setSelectedAnalyzesExcelFiles } from "../../../actions_reducers/actions/files.action";
import CustomScrollDiv from "../../customComponents/CustomScrollBar";
import { ReactComponent as Drop } from "../../../assets/drop.svg";
import { ReactComponent as Pdf } from "../../../assets/pdf.svg";
import { ReactComponent as AddFile } from "../../../assets/addFile.svg";
import { ReactComponent as Cross } from "../../../assets/cross.svg";
import CustomButton from "../../customComponents/CustomButton";
import { setSynthesisOver } from "../../../apiRoutes/analyzes.routes";
import { ReactComponent as ArrowDown } from "../../../assets/arrowdown2.svg";
import CustomCheckbox from "../../customComponents/CustomCheckbox";
import loading from '../../../assets/loading.gif'
import 'moment/locale/fr';
const Moment = require("moment");
const MomentRange = require("moment-range");
const moment = MomentRange.extendMoment(Moment);

export const FilesDrop = (props) => {
    const dispatch = useDispatch();
    const drop = useRef(null);
    const inputRef = useRef(null);
    const dropdownRef = useRef(null);
    const { t } = useTranslation();

    const [files, setFiles] = useState([]);
    const [fileToDelete, setFileToDelete] = useState(null);
    const [dragActive, setDragActive] = useState(false);
    const [dropType, setDropType] = useState("menus");
    const [typeShowed, setTypeShowed] = useState("menus")
    const [modalExcel, setModalExcel] = useState(false);

    const [dropdownAnalyzesOpen, setdropdownAnalyzesOpen] = useState(false);
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target) && event.target.type !== "checkbox") {
                setdropdownAnalyzesOpen(false)
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownRef]);
    useEffect(() => {
        if (props.selected_analysis != null)
            getFilesForAnalysis();
    }, [props.selected_analysis])

    useEffect(() => {
        drop.current.addEventListener('dragover', handleDrag);
        drop.current.addEventListener('dragenter', handleDrag);
        drop.current.addEventListener('dragleave', handleDrag);
        drop.current.addEventListener('drop', handleDrop);
    }, []);

    const getPathMenus = () => {
        let analysisNumber = props.selected_analysis;
        if (props.selected_analysis) {
            let restaurant_name = props.restaurant_name;
            let path = restaurant_name + "/menus/analyse_" + analysisNumber + "/";
            return path;
        }
        else return "";
    }

    const getPathSynthesis = () => {
        let analysisNumber = props.selected_analysis;
        if (props.selected_analysis) {
            let restaurant_name = props.restaurant_name;
            let path = restaurant_name + "/synthesis/analyse_" + analysisNumber + "/";
            return path;
        }
        else return "";
    }

    const getFilesForAnalysis = async () => {
        let path = getPathMenus();
        let pathSynthesis = getPathSynthesis()
        let filesList = await getFilesOfRestaurantAndAnalysis(path);
        let filesListSynthesis = await getFilesOfRestaurantAndAnalysis(pathSynthesis);
        addFilesToList(filesList, filesListSynthesis);
    }

    const handleDrag = function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };
    const handleDrop = function (e) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            setFiles(e.dataTransfer.files);
        }
    };
    const handleChange = (e) => {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
            setFiles(e.target.files);
        }
    };
    const clickInputUpload = () => {
        document.getElementById("file").click();
    }

    const confirmUpload = async () => {
        let names = [];
        for (const file of files) {
            let analysisNumber = props.selected_analysis;
            let url = (dropType === "menus" ? getPathMenus() : getPathSynthesis()) + file.name.replace(/ /g, "_");
            await uploadFilesForRestaurant(url, file, props.restaurant_name, analysisNumber, dropType);
            window.alert(t('errors.confirmFiles'))
            names.push(url);
        }
        if (dropType === "menus") addFilesToList(names, [])
        else {
            let analysisNumber = props.selected_analysis;
            let synthesis = JSON.parse(JSON.stringify(props.analyzesSynthesis))
            let synthesisIndex = synthesis.findIndex(a => a.analysisNumber === parseInt(analysisNumber));
            synthesis[synthesisIndex].hasSynthesis = true;
            dispatch(setAnalyzesSynthesis(synthesis));
            addFilesToList([], names)
            await setSynthesisOver(props.restaurant_name, parseInt(analysisNumber), true);
        }
        setFiles([]);
    }
    const cancelUpload = () => {
        setFiles([]);
        setFileToDelete(null);
    }
    const addFilesToList = (list, synthesis) => {
        let analysisIndex = props.files_list.findIndex(a => a.analysis === props.selected_analysis);
        let tmpFiles = JSON.parse(JSON.stringify(props.files_list));
        if (analysisIndex === -1) {
            let newFiles = {
                analysis: props.selected_analysis,
                files: list,
                synthesis: synthesis
            }
            tmpFiles.push(newFiles);
        }
        else {
            for (const name of list) {
                let indexFile = tmpFiles[analysisIndex].files.findIndex(a => a === name);
                if (indexFile === -1) {
                    tmpFiles[analysisIndex].files.push(name)
                }
                else {
                    tmpFiles[analysisIndex].files[indexFile] = name;
                }
            }
            for (const name of synthesis) {
                let indexFile = tmpFiles[analysisIndex].synthesis.findIndex(a => a === name);
                if (indexFile === -1) {
                    tmpFiles[analysisIndex].synthesis.push(name)
                }
                else {
                    tmpFiles[analysisIndex].synthesis[indexFile] = name;
                }

            }
        }
        dispatch(setFilesList(tmpFiles));
    }

    const getUploadedFiles = () => {
        let result = [];
        for (const file of files) {
            result.push(
                <div style={{ height: "10%", width: "80%", textAlign: "center", alignSelf: "center", color: "#666666" }}>
                    {file.name}
                </div>
            )
        }
        return result
    }
    const getModalConfirm = () => {
        return (
            <div style={{
                height: "100vh",
                width: "100vw",
                zIndex: 10000000000,
                position: "fixed",
                top: 0,
                right: 0,
                backgroundColor: "#66666670",
            }}>
                <CustomBlock
                    position={"fixed"}
                    width={"50vw"}
                    height={"auto"}
                    backgroundColor={"#fff"}
                    top={"20%"}
                    left={"30.5%"}
                    paddingBottom={"1%"}
                    paddingTop={"1%"}
                    paddingLeft={"2%"}
                    paddingRight={"2%"}
                >
                    <div style={{ width: "100%", height: "6vh", fontSize: "1.2vw", fontWeight: "500", color: "#666666" }}>
                        {t('components.confirmMessage')}
                    </div>
                    <div style={{ width: "100%", height: "6vh", color: "#666666" }}>
                        {t('components.stockageMessage')}
                    </div>
                    <div style={{ width: "100%", height: "6vh", color: "#666666" }}>
                        {
                            files.length > 1 ?
                                t('components.multipleFilesUploadText', { "analysis": props.selected_analysis }) :
                                t('components.singleFileUploadText', { "analysis": props.selected_analysis })

                        }
                    </div>
                    {fileToDelete == null ? <div style={{ width: "100%", height: "auto", display: "flex", justifyContent: "center", flexDirection: "column" }}>
                        <div style={{
                            border: "1px dashed #666666", borderRadius: "5px", minHeight: "6vh", height: "auto", width: "70%", alignSelf: "center", display: "flex", justifyContent: "center", flexDirection: "column"
                        }}>
                            {getUploadedFiles()}
                        </div>

                    </div> :
                        <div>
                            {fileToDelete}
                        </div>
                    }
                    <div style={{ height: "2vh" }} />
                    {(props.userState.user_type === "admin" && fileToDelete == null) &&
                        <div style={{ height: "12vh", width: "100%", marginTop: "2.5%", color: "#666666" }}>
                            <div style={{ width: "100%", height: "10%" }}>
                                {t('components.fileType')}
                            </div>
                            <div style={{ width: "100%", height: "90%", display: "flex", flexDirection: "row" }}>
                                <div style={{ width: "50%", justifyContent: "center", display: "flex", alignItems: "center" }} >

                                    <label for={"menus"} style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                        <input style={{ marginRight: "2%" }} type="radio" id={"menus"} name={"type"} value={"menus"} defaultChecked={true} onClick={(e) => setDropType(e.target.value)} />{t('components.menusAndEffectives')}</label>
                                </div>
                                <div style={{ width: "50%", justifyContent: "center", display: "flex", alignItems: "center" }}>
                                    <label for={"synthesis"} style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                        <input style={{ marginRight: "2%" }} type="radio" id={"synthesis"} name={"type"} value={"synthesis"} onClick={(e) => setDropType(e.target.value)} />{t('components.synthesis')}</label>
                                </div>
                            </div>
                        </div>
                    }
                    <div style={{ width: "100%", height: "7vh", display: "flex", flexDirection: "row" }}>
                        <div style={{ width: "50%", height: "100%", display: "flex", justifyContent: "center" }}>
                            <CustomButton
                                width={"50%"}
                                height={"70%"}
                                color={"#fff"}
                                backgroundColor={"#72B9A2"}
                                onClick={() => fileToDelete != null ? deleteFile() : confirmUpload()}>{t('buttons.confirm')}
                            </CustomButton>
                        </div>
                        <div style={{ width: "50%", height: "100%", display: "flex", justifyContent: "center" }}>
                            <CustomButton
                                width={"50%"}
                                height={"70%"}
                                color={"#fff"}
                                backgroundColor={"#666666"}
                                onClick={() => cancelUpload()}>{t('buttons.cancel')}
                            </CustomButton>
                        </div>
                    </div>
                </CustomBlock>
            </div>
        )
    }
    const downloadFile = async (e) => {
        let fileName = e.target.id;
        let url = typeShowed === "synthesis" ? getPathSynthesis() : getPathMenus();
        let path = url + fileName;
        await downloadFileRestaurant(path, fileName);
    }

    const deleteFile = async () => {
        await deleteFileForRestaurant(fileToDelete)
        let analysisIndex = props.files_list.findIndex(a => a.analysis === props.selected_analysis);
        let tmpFiles = JSON.parse(JSON.stringify(props.files_list));
        if (typeShowed === "synthesis") {
            let index = tmpFiles[analysisIndex].synthesis.findIndex(a => a === fileToDelete);
            tmpFiles[analysisIndex].synthesis.splice(index, 1);
        }
        else {
            let index = tmpFiles[analysisIndex].files.findIndex(a => a === fileToDelete);
            tmpFiles[analysisIndex].files.splice(index, 1);
        }
        dispatch(setFilesList(tmpFiles))
        setFileToDelete(null);
    }

    const getFilesList = () => {
        let result = [];
        let currentFiles = props.files_list.find(a => a.analysis === props.selected_analysis);
        if (currentFiles) {
            let files = typeShowed === "synthesis" ? currentFiles.synthesis : currentFiles.files;
            let pathToSplit = typeShowed === "synthesis" ? getPathSynthesis() : getPathMenus()
            for (const file of files) {
                let name = file.split(pathToSplit)[1] && file.split(pathToSplit)[1].replace(/ /g, '');
                result.push(
                    <div style={{ width: "100%", display: "flex", flexDirection: "row", marginBottom: "5%" }}>
                        <div id={name} style={{ width: "80%" }} className="files-drop-row-file" onClick={(e) => downloadFile(e)}>
                            <div id={name} className="files-drop-row-file-icon">
                                <Pdf id={name} style={{ width: "100%", height: "60%" }} />
                            </div>
                            <div id={name} className="files-drop-row-file-name">
                                {name.length > 17 ? name.slice(0, 17) + "..." : name}
                            </div>
                        </div >
                        <div style={{ width: "20%", cursor: "pointer", display: "flex", alignItems: "center" }}>
                            <Cross style={{ width: "80%", height: "80%" }} onClick={() => setFileToDelete(file)} />
                        </div>
                    </div>
                )
            }
        }
        return result;
    }
    const handleDownloadExcel = async () => {
        let final = JSON.parse(JSON.stringify(props.selected_analyzes))
        let index = final.findIndex(a => a === t('components.selectAll'))
        if (index !== -1) final.splice(index, 1)
        if (final.length === 0)
            window.alert(t('errors.atLeastOneAnalysis'))
        else {
            dispatch(setLoadingFiles(true));
            let name = t('components.dataKikleo');
            if (final.length === 1) {
                let analysis = props.allAnalyzes.find(a => a.analysisNumber === parseInt(final[0]));
                let date = moment(analysis.dates[analysis.dates.length - 1]).add(-1, 'week').format("MM_YYYY");
                name = t('components.dataKikleoMonth', { month: date })
            }
            await downloadExcel(props.restaurant_name, props.selected_analyzes, name)
            dispatch(setLoadingFiles(false));
            dispatch(setSelectedAnalyzesExcelFiles([]))
            setModalExcel(false)
        }
    };
    const updateSelectedAnalyzes = (id) => {
        let analyzes = props.allAnalyzes;
        let tmp = JSON.parse(JSON.stringify(props.selected_analyzes))
        if (id !== t('components.selectAll')) {
            let index = tmp.findIndex(a => parseInt(a) === parseInt(id))
            if (index === -1) {
                tmp.push(parseInt(id))
            }
            else {
                tmp.splice(index, 1)
            }
        }
        else {
            if (tmp.length === analyzes.length + 1) {
                tmp = [];
            }
            else {
                for (const analysis of analyzes) {
                    if (!tmp.find(a => parseInt(a) === parseInt(analysis.analysisNumber)) && (analysis.isOver || (props.userState.user_type === "admin" && moment(analysis.dates[0]).isSameOrBefore(moment(new Date())))))
                        tmp.push(parseInt(analysis.analysisNumber));
                }
                tmp.push(t('components.selectAll'));
            }
        }
        dispatch(setSelectedAnalyzesExcelFiles(tmp))
    }

    const getAnalyzesList = () => {
        let analyzes = props.allAnalyzes;
        let values = [];
        for (const analysis of analyzes) {
            if (analysis.isOver || (props.userState.user_type === "admin" && moment(analysis.dates[0]).isSameOrBefore(moment(new Date()))))
                values.push({
                    analysisNumber: analysis.analysisNumber,
                    from: analysis.dates[0],
                    to: analysis.dates[analysis.dates.length - 1]
                })
        }
        values.sort((a, b) => parseInt(b.analysisNumber) - parseInt(a.analysisNumber))
        values.unshift({ analysisNumber: t('components.selectAll') });
        return (
            <div
                ref={dropdownRef}
                className='custom-dropdown'
                id={'general-dropdown-food'}
                style={{
                    height: "100%",
                    width: "70%",
                    color: "#666666",
                    backgroundColor: "#fff",
                    zIndex: dropdownAnalyzesOpen ? 100000 : 1,
                    border: "1px solid #666666",
                    boxShadow: "none"
                }}
                onClick={(e) => { e.target.type !== "checkbox" && setdropdownAnalyzesOpen(!dropdownAnalyzesOpen) }}>
                {t('components.periods')}
                <ArrowDown
                    id={'general-dropdown-food'} className='arrow-down-img' fill={"#666666"} />

                < div className="custom-dropdown-choices"
                    style={{
                        width: "100%",
                        display: dropdownAnalyzesOpen ? 'block' : 'none',
                        backgroundColor: '#fff',
                        borderRight: "1px solid #666666",
                        borderBottom: "1px solid #666666",
                        borderLeft: "1px solid #666666",
                        borderBottomLeftRadius: "10px",
                        borderBottomRightRadius: "10px",
                        height: "40vh",
                        paddingTop: "1%",
                        paddingBottom: "1%"
                    }}>
                    <CustomScrollDiv>
                        <div>
                            {values.map((value) => {
                                return (
                                    <div key={'dropdown-food-' + value} id={value.analysisNumber} className="custom-dropdown-choice-div"
                                        style={{
                                            display: "flex", flexDirection: "row", height: "4.5vh", backgroundColor: "#fff", color: "#666666",
                                            borderBottomLeftRadius: "10px",
                                            borderBottomRightRadius: "10px"
                                        }}
                                    >
                                        <div id={value.analysisNumber} style={{ width: "10%", height: "100%" }} onClick={(e) => ""}>
                                            <CustomCheckbox width={"2vh"} height={"2vh"} checked={props.selected_analyzes.findIndex(a => parseInt(a) === parseInt(value.analysisNumber) || a === t('components.selectAll')) !== -1} id={value.analysisNumber} onChange={(e) => updateSelectedAnalyzes(e.target.id)} />
                                        </div>
                                        <div id={value.analysisNumber} style={{ width: "80%", height: "100%" }} >
                                            {value.analysisNumber !== t('components.selectAll') ?
                                                t(`components.analysisFile`, { analysisNumber: value.analysisNumber, dateStart: t('date', { "value": { "date": value.from, "format": "DD-MM-YYYY" } }), dateEnd: t('date', { "value": { "date": value.to, "format": "DD-MM-YYYY" } }) })
                                                : t('components.selectAll')
                                            }
                                        </div>
                                    </div>
                                )
                            })}

                        </div>
                    </CustomScrollDiv>
                </div>
            </div >

        )
    }
    const getModalExcel = () => {
        if (!props.loadingFiles)
            return (
                <div style={{
                    height: "100vh",
                    width: "100vw",
                    zIndex: 10000000000,
                    position: "fixed",
                    top: 0,
                    right: 0,
                    backgroundColor: "#66666670",
                    display: modalExcel ? "block" : "none"
                }}>
                    <CustomBlock
                        position={"fixed"}
                        width={"50vw"}
                        height={"auto"}
                        backgroundColor={"#fff"}
                        top={"20%"}
                        left={"30.5%"}
                        paddingBottom={"1%"}
                        paddingTop={"1%"}
                        paddingLeft={"2%"}
                        paddingRight={"2%"}
                    >
                        <div style={{ width: "100%", height: "6vh", fontSize: "1.2vw", fontWeight: "500", color: "#666666", justifyContent: "center", display: "flex" }}>
                            {t('components.extractDatas')}
                        </div>
                        <div style={{
                            width: "100%", height: "6vh", color: "#666666", display: "flex",
                            justifyContent: "center"
                        }}>
                            {t('components.selectAnalyzesExtract')}
                        </div>
                        <div style={{
                            height: "6vh", width: "100%", alignSelf: "center", display: "flex",
                            justifyContent: "center"
                        }}>
                            {getAnalyzesList()}
                        </div>
                        <div style={{ height: "3vh" }} />
                        <div style={{ width: "100%", height: "7vh", display: "flex", flexDirection: "row" }}>
                            <div style={{ width: "50%", height: "100%", display: "flex", justifyContent: "center" }}>
                                <CustomButton
                                    width={"50%"}
                                    height={"70%"}
                                    color={"#fff"}
                                    backgroundColor={"#72B9A2"}
                                    onClick={handleDownloadExcel}>
                                    {t('buttons.confirm')}
                                </CustomButton>
                            </div>
                            <div style={{ width: "50%", height: "100%", display: "flex", justifyContent: "center" }}>
                                <CustomButton
                                    width={"50%"}
                                    height={"70%"}
                                    color={"#fff"}
                                    backgroundColor={"#666666"}
                                    onClick={() => {
                                        dispatch(setSelectedAnalyzesExcelFiles([]));
                                        setModalExcel(false)
                                    }}>{t('buttons.cancel')}
                                </CustomButton>
                            </div>
                        </div>
                    </CustomBlock>
                </div>
            )
        else
            return (
                <div style={{
                    height: "100vh",
                    width: "100vw",
                    zIndex: 10000000000,
                    position: "fixed",
                    top: 0,
                    right: 0,
                    backgroundColor: "#66666670",
                    display: modalExcel ? "block" : "none"
                }}>
                    <CustomBlock
                        position={"relative"}
                        width={"50vw"}
                        height={"60vh"}
                        backgroundColor={"#fff"}
                        top={"20%"}
                        left={"30.5%"}
                        paddingBottom={"1%"}
                        paddingTop={"1%"}
                        paddingLeft={"2%"}
                        paddingRight={"2%"}
                    >
                        <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                            <img src={loading} className="animated-gif" />
                            {t('components.excelGeneration')}
                        </div>
                    </CustomBlock>
                </div>)
    }
    const getMonth = () => {
        let date = ""
        let analysis = props.allAnalyzes.find(a => a.analysisNumber === parseInt(props.selected_analysis))
        if (analysis) {
            date = t('date', { value: { date: moment(analysis.dates[analysis.dates.length - 1]).subtract(7, 'days').format('YYYY-MM-DD'), format: 'MMMM YYYY' } })
            date = date.charAt(0).toUpperCase() + date.slice(1)
        }
        return date
    }
    return (
        <CustomBlock
            width={"25%"}
            height={"100%"}
            backgroundColor={"#fff"}
            paddingTop={"1%"}
            paddingBottom={"1%"}
            paddingLeft={"1%"}
            paddingRight={"1%"}>
            {getModalExcel()}
            <div className="dashboard-block-title" style={{ fontSize: "2.1vh" }}>
                {props.userState.user_type === "admin" ? t('components.dropTitle', { "analysis": props.selected_analysis }) : t('components.dropTitleClient', { "month": getMonth() })}
            </div>
            <div style={{ height: "2%" }} />
            {props.userState.user_type === "admin" ? <div style={{ height: "5%" }}>
                <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                    <div style={{
                        display: "flex", justifyContent: "center", alignItems: "center", color: "#fff",
                        width: "40%", height: "80%", borderTopLeftRadius: "5px", borderBottomLeftRadius: "5px", backgroundColor: "#90c261"
                    }}
                        onClick={() => setTypeShowed("synthesis")}>
                        {t('components.synthesis')}
                    </div>
                    <div style={{
                        display: "flex", justifyContent: "center", alignItems: "center", color: "#fff",
                        width: "40%", height: "80%", borderTopRightRadius: "5px", borderBottomRightRadius: "5px", backgroundColor: "#54b0e3"
                    }}
                        onClick={() => setTypeShowed("menus")}>
                        {t('table.menus')}
                    </div>
                </div>
            </div>
                :
                <div style={{ height: "5%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <CustomButton
                        width={"100%"}
                        height={"100%"}
                        backgroundColor={'#90c261'}
                        color={"#fff"}
                        fontSize={"clamp(.5rem,.5rem,1rem)"}
                        onClick={() => setModalExcel(true)}>{t('buttons.downloadExcel')}</CustomButton>
                </div>
            }
            {props.userState.user_type !== "admin" ?
                <div className="files-drop-text">
                    {t('longTexts.dropText')}
                </div> :
                <div style={{ height: "20%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <CustomButton
                        width={"100%"}
                        height={"25%"}
                        backgroundColor={'#90c261'}
                        color={"#fff"}
                        fontSize={"clamp(.75rem,.9rem,2rem)"}
                        onClick={() => setModalExcel(true)}>{t('buttons.downloadExcel')}</CustomButton>
                </div>
            }
            <div className="files-drop-list">
                <CustomScrollDiv update={Math.random()}>
                    {getFilesList()}
                </CustomScrollDiv>

            </div>
            <div style={{ height: "5%" }} />
            <div className="files-drop-uploader"
                style={{ backgroundColor: dragActive && "#949494", border: dragActive && "none", cursor: "pointer", height: "38%" }}
                ref={drop}
                onClick={() => clickInputUpload()}
            >
                <input ref={inputRef} name="file" id="file" type="file" className="files-drop-input" multiple={true} onChange={handleChange} />
                {!dragActive ?
                    <>
                        <Drop for="file" className="drop-file-svg" />
                        <div className="files-drop-drag-text">{t('components.dragFiles')}</div>
                    </>
                    :
                    <AddFile style={{ width: "100%", height: "30%", fill: "#fff" }} />
                }
            </div >
            {(files.length > 0 || fileToDelete != null) && getModalConfirm()}
        </CustomBlock>
    )
}