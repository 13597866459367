import React from "react";
import CustomBlock from "../../../customComponents/CustomBlock";
import { ReactComponent as Tray } from "../../../../assets/tray.svg";
import { ReactComponent as Euro } from "../../../../assets/euro.svg";
import { ReactComponent as Kg } from "../../../../assets/kg.svg";
import { ReactComponent as Earth } from "../../../../assets/earth.svg";
import { ReactComponent as Cutlery } from "../../../../assets/cutlery.svg";
import { useTranslation } from 'react-i18next';
import { setSelectedAnalysisType } from "../../../../actions_reducers/actions/dashboard.action";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { ReactComponent as Information } from "../../../../assets/informationButton.svg";
const Moment = require("moment");
const MomentRange = require("moment-range");
const moment = MomentRange.extendMoment(Moment);

const AnalysisValues = (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const selected = props.state.selectedAnalysisType;
    const marginLeft = '4%';
    const width = '20%';
    const height = '100%';
    const [showInfo, setShowInfo] = useState("")

    const getInfo = (type) => {
        return (
            <Information fill={"#FFFFFF"} style={{ width: "2vw", height: "3vh", top: "4%", right: "1%", position: "absolute" }}
                onMouseLeave={() => setShowInfo("")}
                onMouseEnter={() => setShowInfo(type)} />)
    }

    const getTraysTotal = () => {
        let total = 0
        let days = [];
        let analysis = props.analysis;
        let kitchenAnalysis = props.kitchenAnalysis;
        if (analysis) {
            for (const day of analysis.days) {
                if (props.state.selectedPieCategory == null) {
                    total = parseInt(total) + parseInt(day.trays)
                    days.push(moment(day.date).format("YYYY-MM-DD"));
                }
                else {
                    let catValues = day.categories.find(a => a.category === props.state.selectedPieCategory);
                    total = parseInt(total) + parseInt(catValues.trays)
                    days.push(moment(day.date).format("YYYY-MM-DD"));
                }
            }
        }
        if (kitchenAnalysis) {
            for (const day of kitchenAnalysis.days) {
                if (props.state.selectedPieCategory == null) {
                    if (!days.find(a => a === moment(day.date).format("YYYY-MM-DD"))) {
                        total = parseInt(total) + parseInt(day.trays)
                        days.push(moment(day.date).format("YYYY-MM-DD"));
                    }
                }
                else {
                    let catValues = day.categories.find(a => a.category === props.state.selectedPieCategory);
                    if (catValues && !days.find(a => a === day.date)) {
                        total = parseInt(total) + parseInt(catValues.trays)
                        days.push(moment(day.date).format("YYYY-MM-DD"));
                    }
                }
            }
        }
        return total;
    }
    const getValues = () => {
        let massByConv = 0;
        let massByConvKitch = 0;
        let mass = 0;
        let cost = 0;
        let co2 = 0;
        let bread = 0;
        let massKitchen = 0;
        let costKitchen = 0;
        let co2Kitchen = 0;
        let analysis = props.analysis;
        let kitchenAnalysis = props.kitchenAnalysis;
        let baseAnalysis = props.baseAnalysis;
        let baseAnalysisKitch = props.baseAnalysisKitch;

        if (analysis) {
            massByConv = (parseFloat(baseAnalysis.mass) - parseFloat(baseAnalysis.inevitableMass)) / parseInt(baseAnalysis.trays);
            mass = (parseFloat(analysis.mass) - parseFloat(analysis.inevitableMass)).toFixed(0);
            cost = (parseFloat(analysis.cost) - parseFloat(analysis.inevitableCost)).toFixed(0);
            co2 = (parseFloat(analysis.co2) - parseFloat(analysis.inevitableCo2)).toFixed(0);
            for (const day of analysis.days) {
                for (const category of day.categories) {
                    if (category.category === "Pain") {
                        bread = parseFloat(bread) + parseFloat(category.mass)
                    }
                }
            }
        }
        if (props.has_kitchen) {
            if (kitchenAnalysis) {
                massByConvKitch = (parseFloat(baseAnalysisKitch.mass) - parseFloat(baseAnalysisKitch.inevitableMass)) / parseInt(baseAnalysisKitch.trays);
                massKitchen = (parseFloat(kitchenAnalysis.mass) - parseFloat(kitchenAnalysis.inevitableMass)).toFixed(0);
                costKitchen = (parseFloat(kitchenAnalysis.cost) - parseFloat(kitchenAnalysis.inevitableCost)).toFixed(0);
                co2Kitchen = (parseFloat(kitchenAnalysis.co2) - parseFloat(kitchenAnalysis.inevitableCo2)).toFixed(0);
                for (const day of kitchenAnalysis.days) {
                    for (const category of day.categories) {
                        if (category.category === "Pain") {
                            bread = parseFloat(bread) + parseFloat(category.mass)
                        }
                    }
                }
            }
        }
        return {
            massByConv: parseFloat(massByConv) + parseFloat(massByConvKitch),
            mass: props.has_kitchen ? parseFloat(mass) + parseFloat(massKitchen) : parseFloat(mass),
            cost: props.has_kitchen ? parseFloat(cost) + parseFloat(costKitchen) : parseFloat(cost),
            co2: props.has_kitchen ? parseFloat(co2) + parseFloat(co2Kitchen) : parseFloat(co2),
            breadNumber: (bread / 50).toFixed(0),
            bread: bread
        }
    }
    return (
        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', marginLeft: 0, marginTop: '4vh' }}>
            <CustomBlock width={width} height={height} backgroundColor={'#5BB3E4'} hasHover={true} selected={selected === "massByConv"}>
                <div className="dashboard-block-values" onClick={() => dispatch(setSelectedAnalysisType('massByConv'))}>
                    <div className="dashboard-analysis-row-icon-value">
                        <div className="dashboard-block-values-icon-analysis">
                            <Tray style={{ height: '100%', width: '100%' }} />
                            {/*props.userState.selectedAnalysisDataType.find(a => a === "restaurant") ?
                                <Tray style={{ height: '100%', width: '100%' }} />
                                :
    <Scale style={{ height: '65%', width: '100%', marginTop: "-15%" }} />*/}
                        </div>
                        <div className="dashboard-block-values-analysis-value">
                            {t('mass', { "value": { "mass": getValues().massByConv, "unit": "g" } })}
                        </div>
                    </div>
                    <div className="dashboard-block-values-legend">
                        {t('components.average')}
                    </div>
                </div>
            </CustomBlock>
            <CustomBlock width={width} height={height} marginLeft={marginLeft} hasHover={true} position={"relative"} backgroundColor={'#7DCBB9'} selected={selected === "mass"}>
                {getInfo("mass")}
                {showInfo === "mass" && (
                    <div className="window-info" style={{ width: "25vw", position: "absolute", bottom: "100%", right: "-23vw", zIndex: "874656783", backgroundColor: "white", padding: "10px", borderRadius: "10px", fontSize: "2vh", boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}>{t("components.bioWasteInfo")}</div>
                )}
                <div className="dashboard-block-values" onClick={() => dispatch(setSelectedAnalysisType('mass'))}>
                    <div className="dashboard-analysis-row-icon-value">
                        <div className="dashboard-block-values-icon-analysis">
                            <Kg style={{ height: '100%', width: '100%' }} />
                        </div>
                        <div className="dashboard-block-values-analysis-value">
                            {t('mass', { "value": { "mass": getValues().mass, "unit": "kg" } })}
                        </div>
                    </div>
                    <div className="dashboard-block-values-legend" style={{ width: "85%", paddingLeft: "15.5%" }}>
                        {t('components.wastedTotal')}
                    </div>
                </div>
            </CustomBlock>
            <CustomBlock width={width} height={height} marginLeft={marginLeft} position={"relative"} hasHover={true} backgroundColor={'#89CD85'} selected={selected === "cost"}>
                {getInfo("cost")}
                {showInfo === "cost" && (
                    <div className="window-info" style={{ width: "25vw", position: "absolute", bottom: "100%", right: "-24vw", zIndex: "874656783", backgroundColor: "white", padding: "10px", borderRadius: "10px", fontSize: "2vh", boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}>{t("components.costInfo")}</div>
                )}
                <div className="dashboard-block-values" onClick={() => dispatch(setSelectedAnalysisType('cost'))}>
                    <div className="dashboard-analysis-row-icon-value">
                        <div className="dashboard-block-values-icon-analysis">
                            <Euro style={{ height: '100%', width: '100%' }} />
                        </div>
                        <div className="dashboard-block-values-analysis-value">
                            {t('currency', { "value": { cost: getValues().cost } })}
                        </div>
                    </div>
                    <div className="dashboard-block-values-legend">
                        {t('components.economicLoss')}
                    </div>
                </div>
            </CustomBlock>
            <CustomBlock width={width} marginLeft={marginLeft} height={height} position={"relative"} hasHover={true} backgroundColor={'#90c261'} selected={selected === "co2"}>
                {getInfo("co2")}
                {showInfo === "co2" && (
                    <div className="window-info" style={{ width: "19vw", position: "absolute", bottom: "100%", right: "-18vw", zIndex: "874656783", backgroundColor: "white", padding: "10px", borderRadius: "10px", fontSize: "2vh", boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}>{t("components.co2Info")}</div>
                )}
                <div className="dashboard-block-values" onClick={() => dispatch(setSelectedAnalysisType('co2'))}>
                    <div className="dashboard-analysis-row-icon-value">
                        <div className="dashboard-block-values-icon-analysis">
                            <Earth style={{ height: '100%', width: '100%' }} />
                        </div>
                        <div className="dashboard-block-values-analysis-value">
                            {t('mass', { "value": { "mass": getValues().co2, "unit": "kg" } })}
                        </div>
                    </div>
                    <div className="dashboard-block-values-legend">
                        {t('components.environmentalImpact')}
                    </div>
                </div>
            </CustomBlock>
            <CustomBlock width={width} marginLeft={marginLeft} height={height} position={"relative"} hasHover={true}
                backgroundColor={'#666666'}
                selected={selected === "trays"}>
                <div className="dashboard-block-values" onClick={() => dispatch(setSelectedAnalysisType('trays'))}>
                    <div className="dashboard-analysis-row-icon-value">
                        <div className="dashboard-block-values-icon-analysis" style={{ marginTop: "-1%" }}>
                            <Cutlery style={{ height: '55%', width: '90%' }} />
                        </div>
                        <div className="dashboard-block-values-analysis-value">
                            {getTraysTotal()}
                        </div>
                    </div>
                    <div className="dashboard-block-values-legend">
                        {t('components.numberTrays')}
                    </div>
                </div>
            </CustomBlock>


            {/*
            <div style={{ display: "flex", flexDirection: "column", width: width, marginLeft: marginLeft, height: "42vh" }}>
                <CustomBlock width={"100%"} marginTop={"9%"} height={"41%"} hasHover={true} backgroundColor={'#EA881F'} selected={selected === "co2"}>
                    <div className="dashboard-block-values" onClick={() => dispatch(setSelectedAnalysisType('co2'))}>
                        <div className="dashboard-analysis-row-icon-value">
                            <div className="dashboard-block-values-icon-analysis">
                                <Bread style={{ height: '100%', width: '100%' }} />
                            </div>
                            <div className="dashboard-block-values-analysis-value">
                                {`x ${getValues().breadNumber}`}
                            </div>
                        </div>
                        <div className="dashboard-block-values-legend" style={{ height: "10%" }}>
                            {t('components.bread')}
                        </div>
                        <div className="dashboard-block-values-second-legend" style={{ color: "#6D6C7A", fontSize: '0.9vw' }}>
                            {t('components.wasteByConv', { value:  t('mass', { "value": { "mass": getValues().bread / getValues().trays, "unit": "g" } })  })}
                        </div>
                    </div>
    </CustomBlock></div>*/}

        </div>
    )
}

export default AnalysisValues
